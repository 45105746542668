<template>
  <div class="licenced-product-modal">
    <vs-popup class="licenced-product-modal--popup" title="Neues lizenziertes Produkt hinzufügen" @close="onClose" :active="visible">
      <div v-show="dataTransfer">
        <div class="vx-row mb-2 p-4">
          <div class="vx-col w-full mb-0">
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Vertrag auswählen:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <mg-select v-model="dataTransfer.contract_id" :options="contracts" name="contract"
                           placeholder="Vertrag auswählen"
                           track-by="id" label="contract_number" data-vv-as="Vertrag" v-validate="'required'"
                           :custom-label="customContractLabel"
                           @input="fetchContractItems">
                </mg-select>
                <span class="text-danger text-sm" v-if="errors.has('contract')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>

            <vs-row class="mt-4" v-if="contractItems.length">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Vertragsposition auswählen:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <mg-select v-model="dataTransfer.contract_item_id" :options="contractItems" name="contract_item"
                           placeholder="Vertragsposition auswählen"
                           track-by="id" label="product_name" data-vv-as="Vertragsposition" v-validate="'required'">
                </mg-select>
                <span class="text-danger text-sm" v-if="errors.has('contract_item')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>
            <vs-row v-if="dataTransfer.contract_item_id">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Ausgewähltes Produkt</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9" class="mb-5" >
                <span v-if="dataTransfer.contract_item_id" class="mt-3 text-bold">{{ contractItems.find(item => item.id === dataTransfer.contract_item_id).article.name }}</span>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>

      <div class="flex p-2 justify-end bg-gray-200 rounded-b">
        <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
        <vs-button @click="createData" :disabled="!formValid">Lizenziertes Produkt hinzufügen</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ApiService from "../../api";
import MgSelect from "../../components/mg-select/MgSelect";
import * as qs from "qs";
import {mapGetters} from "vuex";

export default {
  name: "create-licenced-product-modal",
  components: {
    MgSelect
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    licence: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataTransfer: {
        contract_id: null,
        contract_item_id: null
      },
      contracts: [],
      contractItems: [],
      newData: {
        "contract_id": null,
        "contract_item_id": null,
      }
    }
  },
  created() {
    this.fetchContracts();
  },
  computed: {
    ...mapGetters(['userHasPermission', 'userId', 'user']),
    visible() {
      return this.active;
    },
    formValid() {
      return !this.errors.any();
    },
  },
  methods: {
    openModal() {
      if (!this.dataTransfer) {
        this.dataTransfer = JSON.parse(JSON.stringify(this.newData));
      }
      this.fetchContracts();
    },
    fetchContracts() {

      let queryParams = {
        filter: [
          {
            field: 'client_id',
            expression: 'exact',
            value:  this.licence.owner_client_id
          }
        ]
      };

      ApiService.get('contracts', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      })
          .then(response => {
            if (response.data.status === 'success') {
              this.contracts = response.data.result;
            } else {
              this.$vs.notify({
                title: 'Fehler beim Laden der Verträge',
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehler beim Laden der Verträge',
              text: '',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          });
    },
    fetchContractItems(contract_id) {
      ApiService.get(`contracts/${contract_id}`)
          .then(response => {
            if (response.data.status === 'success') {
              this.contractItems = response.data.result.items;
            } else {
              this.$vs.notify({
                title: 'Fehler beim Laden der Vertragspositionen',
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehler beim Laden der Vertragspositionen',
              text: '',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          });
    },
    onClose() {
      this.$emit('update:active', false);
      this.$emit('close');
    },
    onSave() {
      console.log('saved');
      this.$emit('update:active', false);
      this.$emit('saved');
      this.$emit('on-change-query');
    },
    createData() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const payload = JSON.parse(JSON.stringify(this.dataTransfer));


          ApiService.post('/b2b-sellers/licence/'+ this.$route.params.id +'/products', payload).then((response) => {
            if (response.data.status == "success") {
              this.$vs.loading.close();
              this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Das lizenziertes Produkt wurde erfolgreich erstellt.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              });
              this.onSave();
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: 'Fehler',
                text: "Das lizenziertes Produkt konnte nicht angelegt werden",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            }
          }).catch((error) => {

            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Fehler',
              text: (error.response.data.message) ? error.response.data.message : error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          });
        } else {
          this.$vs.notify({
            title: 'Fehlgeschlagen',
            text: this.$validator.errors.all(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        }
      })
    },
    customContractLabel(option) {

      let label = ``;

      if(option.status == 2 || option.status == 3) {
        label = label +`<span class="text-danger">Gekündigt oder Beendet</span>`;
      }

      label = label + `${option.contract_number} - ${option.type.name} - Start: ${option.contract_start}`;

      return label;
    },
  },
  watch: {
    active: function (val) {
      if (val === true) {
        this.openModal();
      }
    },
  }
}
</script>

<style lang="scss">
.licenced-product-modal--popup {
  .vs-popup {
    min-width: 700px;
    max-width: 100vw;
  }

  .vs-popup--content {
    padding: 5px 0 0 0;
    margin: 0;
    min-height: 60px;
    width: 100%;
  }
}
</style>
